import { useContext, useEffect, useState } from "react";
import "./order-tab-content.scoped.scss";
import { useTranslation } from "react-i18next";
import { CurrencyContext } from "../../../../../context/CurrencyContext";
import { AuthUserContext } from "../../../../../context/AuthUserContext";
import { LoadingContext } from "../../../../../context/LoadingContext";
import {
    IconChevronDown,
    IconChevronUp,
    IconCircleX,
    IconPhoto,
    IconStar,
    IconStarFilled,
    IconX
} from "@tabler/icons-react";
import Modal from "react-bootstrap/Modal";
import Api from "../../../../../utils/Api";
import { toast } from "react-hot-toast";
import { LanguageContext } from "../../../../../context/LanguageContext";

const RATE = [1, 2, 3, 4, 5];

function OrderCard({ order, loadOrder }) {
    /**
     * Hooks
     *
     */
    const { t } = useTranslation();
    const { currency } = useContext(CurrencyContext);
    const formater = new Intl.NumberFormat(currency == "id" ? "id-ID" : "en-EN", {
        style: "currency",
        currency: currency == "id" ? "IDR" : "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });

    /**
     * Context
     *
     */
    const { user } = useContext(AuthUserContext);
    const { setLoading } = useContext(LoadingContext);
    const { language } = useContext(LanguageContext);

    /**
     * State
     *
     */
    const [viewMore, setViewMore] = useState(false);
    const [modalReview, setModalReview] = useState(false);
    const [orderStatusObj, setOrderStatusObj] = useState({});
    const [rateProducts, setRateProducts] = useState(
        order.detail.map(detail => {
            return {
                ...detail,
                rate: 0,
                comment: "",
                imageBlobs: []
            };
        })
    );

    /**
     * Deps
     *
     */
    useEffect(() => {
        if (order.expired_date) {
            setOrderStatusObj({
                status: "danger",
                message: "Pesanan Telah Expired",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-clock"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.5 21h-4.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h10" />
                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                        <path d="M18 16.5v1.5l.5 .5" />
                    </svg>
                )
            });
        }

        if (!order.paid_date) {
            setOrderStatusObj({
                status: "danger",
                message: "Belum Bayar",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-clock-pause"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M20.942 13.018a9 9 0 1 0 -7.909 7.922" />
                        <path d="M12 7v5l2 2" />
                        <path d="M17 17v5" />
                        <path d="M21 17v5" />
                    </svg>
                )
            });
        }

        if (order.paid_date) {
            setOrderStatusObj({
                status: "success",
                message: "Pesanan Dibayarkan",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-cash"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                        <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                    </svg>
                )
            });
        }

        if (order.sent_date) {
            setOrderStatusObj({
                status: "success",
                message: "Pesanan Dikirimkan",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-truck"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
                    </svg>
                )
            });
        }

        if (order.completed_date || order.invoice) {
            setOrderStatusObj({
                status: "success",
                message: "Pesanan Telah Selesai",
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-circle-check"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M9 12l2 2l4 -4" />
                    </svg>
                )
            });
        }
    }, [order]);

    const doReview = () => {
        const form_data_insert = new FormData();
        form_data_insert.set(`order_id`, order.id);

        for (let i = 0; i < rateProducts.length; i++) {
            const product = rateProducts[i];
            form_data_insert.set(`reviews[${i}][order_detail_id]`, product.id);
            form_data_insert.set(`reviews[${i}][rating]`, product.rate);
            form_data_insert.set(`reviews[${i}][review]`, product.comment);

            for (let j = 0; j < product.imageBlobs.length; j++) {
                const { file } = product.imageBlobs[j];
                form_data_insert.set(`reviews[${i}][image][${j}]`, file);
            }
        }

        setLoading(true);
        Api.post("/order-review", form_data_insert, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("apiToken")
            }
        })
            .then(res => {
                console.log(res.data);
                setModalReview(false);
                toast(
                    <div style={{ textAlign: "center" }}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="220"
                                height="202.89"
                                viewBox="0 0 90 83"
                                fill="none"
                            >
                                <path
                                    d="M26.25 76.082H63.75"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 58.791V13.8327C7.5 11.9983 8.29018 10.239 9.6967 8.94186C11.1032 7.64473 13.0109 6.91602 15 6.91602H75C76.9891 6.91602 78.8968 7.64473 80.3033 8.94186C81.7098 10.239 82.5 11.9983 82.5 13.8327V58.791C82.5 60.6254 81.7098 62.3847 80.3033 63.6818C78.8968 64.979 76.9891 65.7077 75 65.7077H15C13.0109 65.7077 11.1032 64.979 9.6967 63.6818C8.29018 62.3847 7.5 60.6254 7.5 58.791Z"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                />
                                <path
                                    d="M33.75 36.3112L41.25 43.2279L56.25 29.3945"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div className="mt-3">{t("toastorderreviewsuccess")}</div>
                    </div>
                );
                loadOrder();
            })
            .catch(err => {
                toast(
                    <div style={{ textAlign: "center" }}>
                        <div>
                            <IconCircleX size={212} color={`#ff3333`} />
                        </div>
                        <div>{t("toastorderreviewfailed")}</div>
                    </div>
                );
            })
            .finally(() => {
                setLoading(false);
            });

        setModalReview(false);
    };

    const doOrderReceived = id => {
        setLoading(true);

        Api.post(
            `/order/${id}/completed`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("apiToken")
                }
            }
        )
            .then(res => {
                toast(
                    <div style={{ textAlign: "center" }}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="220"
                                height="202.89"
                                viewBox="0 0 90 83"
                                fill="none"
                            >
                                <path
                                    d="M26.25 76.082H63.75"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 58.791V13.8327C7.5 11.9983 8.29018 10.239 9.6967 8.94186C11.1032 7.64473 13.0109 6.91602 15 6.91602H75C76.9891 6.91602 78.8968 7.64473 80.3033 8.94186C81.7098 10.239 82.5 11.9983 82.5 13.8327V58.791C82.5 60.6254 81.7098 62.3847 80.3033 63.6818C78.8968 64.979 76.9891 65.7077 75 65.7077H15C13.0109 65.7077 11.1032 64.979 9.6967 63.6818C8.29018 62.3847 7.5 60.6254 7.5 58.791Z"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                />
                                <path
                                    d="M33.75 36.3112L41.25 43.2279L56.25 29.3945"
                                    stroke="#00AE65"
                                    stroke-width="8"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div className="mt-3">{t("toastordercompletedsuccess")}</div>
                    </div>
                );

                loadOrder();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const checkOrderHasBeenReview = orderProducts => {
        if (!orderProducts) {
            return false;
        }

        if (orderProducts.length == 0) {
            return false;
        }

        let isReviewed = true;

        orderProducts.forEach(order => {
            if (order.product.review.length == 0) {
                isReviewed = false;
            }
        });

        return isReviewed;
    };

    const doOpenReceipt = order => {
        console.log(order);
        window.open(order.invoice, "_blank");
    };

    const doContactSeller = order => {
        const text =
            language == "id"
                ? `Halo, LuxuryHub%0asaya mau konfirmasi pesanan dengan Nomor Invoice ${order.invoice_id}`
                : `Hello, LuxuryHub%0aI want to confirm the order with Invoice Number ${order.invoice_id}`;

        const link = "https://api.whatsapp.com/send?phone=6281804058981&text=" + text;

        window.open(link, "_blank");
    };

    return (
        <div className="inner">
            {/* Modal Review */}
            <Modal
                show={modalReview}
                centered
                size="lg"
                onHide={() => {
                    setModalReview(false);
                }}
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("orderreviewproduct")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {rateProducts.map((detail, i) => {
                        return (
                            <>
                                {i != 0 ? <div className="modal-review-divider" /> : null}
                                <div className="modal-review">
                                    <div className="detail">
                                        <img src={detail.product.images[0]} alt="" />
                                        <div>{detail.product.name}</div>
                                    </div>
                                    <div className="rate">
                                        <div>{t("qualityproduct")}</div>
                                        <div className="stars">
                                            {RATE.map(v => (
                                                <button
                                                    onClick={() => {
                                                        const r = [...rateProducts];
                                                        r[i].rate = v;
                                                        setRateProducts(r);
                                                    }}
                                                >
                                                    {v <= detail.rate ? <IconStarFilled /> : <IconStar />}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="comment-box">
                                        <textarea
                                            placeholder={t("comment")}
                                            rows={4}
                                            value={detail.comment}
                                            onInput={e => {
                                                const r = [...rateProducts];
                                                r[i].comment = e.currentTarget.value;
                                                setRateProducts(r);
                                            }}
                                        />
                                        <div className="photos">
                                            {detail.imageBlobs.map(({ url }, j) => (
                                                <div className="photo">
                                                    <img src={url} alt="preview" />
                                                    <button
                                                        onClick={() => {
                                                            const r = [...rateProducts];
                                                            r[i].imageBlobs = r[i].imageBlobs.filter(({ url }, k) => {
                                                                const z = j != k;
                                                                if (!z) {
                                                                    URL.revokeObjectURL(url);
                                                                }
                                                                return z;
                                                            });
                                                            setRateProducts(r);
                                                        }}
                                                    >
                                                        <IconX />
                                                    </button>
                                                </div>
                                            ))}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                hidden
                                                onChange={e => {
                                                    const file = e.currentTarget.files[0];
                                                    const r = [...rateProducts];
                                                    r[i].imageBlobs.push({
                                                        file: file,
                                                        url: URL.createObjectURL(file)
                                                    });
                                                    setRateProducts(r);
                                                }}
                                            />
                                            <button
                                                className="add-photo"
                                                onClick={e => e.currentTarget.previousElementSibling?.click()}
                                            >
                                                <IconPhoto /> {t("addphoto")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-review-bottom">
                        <button
                            onClick={() => {
                                setModalReview(false);
                            }}
                        >
                            {t("cancel")}
                        </button>
                        <button
                            onClick={() => {
                                doReview();
                            }}
                        >
                            {t("submit")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* End of Modal Voucher */}
            <div className="status" data-status={order.status.toLowerCase()}>
                {t("orderstatus")}:{" "}
                <strong>
                    {t("order")} {t(order.status.toLowerCase())}
                </strong>{" "}
                / <a href="#">{order.invoice_id}</a> / {user.name} / Status:{" "}
                <strong>{t(order.status.toLowerCase())}</strong> / {order.date}
            </div>
            <div className="items">
                {(viewMore ? order.detail : order.detail.slice(0, 1)).map((detail, i) => {
                    return (
                        <div className="item">
                            <img src={detail.product.images[0]} alt="" />
                            <div className="product-detail">
                                <div>
                                    <div className="title">s{detail.product.name}</div>
                                    <div>
                                        {detail.qty} x {formater.format(Number(detail.price))}
                                    </div>
                                </div>
                                <div className="text-center">
                                    {i == 0 ? (
                                        <>
                                            <div className="title">{t("address")}</div>
                                            <div>{order.address.full_address}</div>
                                        </>
                                    ) : null}
                                </div>
                                <div className="text-center">
                                    {i == 0 ? (
                                        <>
                                            <div className="title">{t("courier")}</div>
                                            <div>{formater.format(Number(order.ongkir))}</div>
                                        </>
                                    ) : null}
                                </div>
                                <div className="text-center">
                                    {i == 0 ? (
                                        <>
                                            <div className="title">{t("paymentoption")}</div>
                                            <div>{order.payment.invoice_url ? "Bank" : "Offline"}</div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
                {order.detail.length > 1 ? (
                    <div className="text-center">
                        <button className="view-more" onClick={() => setViewMore(c => !c)}>
                            {viewMore ? (
                                <>
                                    {t("viewless")} <IconChevronUp />
                                </>
                            ) : (
                                <>
                                    {t("viewmore")} <IconChevronDown />
                                </>
                            )}
                        </button>
                    </div>
                ) : null}
                <div
                    className={`footer-item ${
                        order.detail.length > 1 || order.status == "Completed" ? "border-top-no-bb" : ""
                    }`}
                >
                    {t("totalpayment")} : <span>{formater.format(Number(order.total))}</span>
                </div>
                {order.status == "On Going" || order.status == "Unpaid" || order.status == "On Process" ? (
                    <div className={`review-item`}>
                        <div className={`left ${orderStatusObj.status}`}>
                            {orderStatusObj.icon}
                            <p>{orderStatusObj.message}</p>
                        </div>
                        <div className="right">
                            <button
                                onClick={() => {
                                    window.location.href = "/account/orders/detail/" + order.id;
                                }}
                            >
                                Lihat Detail Pesanan
                            </button>
                            {order.status == "On Going" ? (
                                <button
                                    type="button"
                                    onClick={() => {
                                        doOrderReceived(order.id);
                                    }}
                                >
                                    {t("orderreceived")}
                                </button>
                            ) : (
                                <></>
                            )}
                            <button
                                onClick={() => {
                                    doContactSeller(order);
                                }}
                            >
                                {t("contactseller")}
                            </button>
                        </div>
                    </div>
                ) : null}
                {order.status == "Completed" ? (
                    <div className={`review-item`}>
                        <div className={`left ${orderStatusObj.status}`}>
                            {orderStatusObj.icon}
                            <p>{orderStatusObj.message}</p>
                        </div>
                        <div className="right">
                            <button
                                onClick={() => {
                                    window.location.href = "/account/orders/detail/" + order.id;
                                }}
                            >
                                Lihat Detail Pesanan
                            </button>
                            {checkOrderHasBeenReview(order.detail) ? (
                                <button
                                    onClick={() => {
                                        doOpenReceipt(order);
                                    }}
                                >
                                    {t("orderreceipt")}
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        setModalReview(true);
                                    }}
                                >
                                    {t("orderreview")}
                                </button>
                            )}

                            <button
                                onClick={() => {
                                    doContactSeller(order);
                                }}
                            >
                                {t("contactseller")}
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default function OrderTabContentComponent({ orders, loadOrder }) {
    return (
        <div className="order-tab-content">
            <div className="body-order-history-tab-content">
                {orders.map(order => {
                    return <OrderCard key={order.id} order={order} loadOrder={loadOrder} />;
                })}
            </div>
        </div>
    );
}
