import { IconDotsVertical, IconStarFilled, IconThumbUp } from "@tabler/icons-react";
import "./review-item.scoped.scss";
import noPhoto from "./../../../../../images/icons/no-photo.png";

export default function ReviewItemComponent({ review }) {
    const stars = [];

    for (let i = 0; i < review.rating; i++) {
        stars.push(<IconStarFilled size={15} style={{ color: "#FFAC33" }} />);
    }
    for (let i = 0; i < 5 - review.rating; i++) {
        stars.push(<IconStarFilled size={15} style={{ color: "#CEC9C1" }} />);
    }

    return (
        <div className="review-item">
            <div className="inner">
                <div className="top">
                    <div className="left">
                        <div className="photo-wrap">
                            <img src={review.user.image ? review.user.image : noPhoto} alt="" />
                        </div>
                        <div className="profile-wrap">
                            <h3>{review.user.name}</h3>
                            <div className="stars">{stars}</div>
                            <span className="date">{review.date}</span>
                        </div>
                    </div>
                    <div className="right">
                        <div className="other-wrap">
                            <IconDotsVertical size={15} style={{ color: "#333333" }} />
                        </div>
                    </div>
                </div>
                <div className="body">
                    <p>{review.review}</p>
                </div>
                <div className="images">
                    {review.images.map(u => {
                        return <img src={u} alt="" />;
                    })}
                </div>
                {/* <div className="actions">
                    <IconThumbUp size={20} style={{ color: "#C4C4C4" }} />
                    <span>3</span>
                </div> */}
            </div>
        </div>
    );
}
