import { useContext, useEffect, useState } from "react";
import AccountOrderLayoutComponent from "../../../components/general/account-order-layout/AccountOrderLayoutComponent";
import "./account-order-detail.scoped.scss";
import Api from "../../../utils/Api";
import { useParams } from "react-router-dom";
import StringUtil from "../../../utils/StringUtil";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { LoadingContext } from "../../../context/LoadingContext";

export default function AccountOrderDetail() {
    /**
     * Params
     *
     */
    const { id } = useParams();

    /**
     * Context
     *
     */
    const { setLoading } = useContext(LoadingContext);

    /**
     * Main States
     *
     */
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [resiObj, setResiObj] = useState({});
    const [orderObj, setOrderObj] = useState({});
    const [stepOrders, setStepOrders] = useState([]);

    /**
     * Deps
     *
     */
    useEffect(() => {
        loadBreadcrumb();
        loadOrderObject(id);
    }, []);

    useEffect(() => {
        loadStepOrders();

        if (orderObj.courier && orderObj.resi) {
            loadResi();
        }
    }, [orderObj]);

    /**
     * Methods
     *
     */
    const loadBreadcrumb = () => {
        setBreadcrumb([
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Orders",
                url: "/account/orders"
            },
            {
                label: "Order Detail"
            }
        ]);
    };

    const doCancel = id => {
        setLoading(true);
        Api.post(
            "/order/" + id + "/cancel",
            {},
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("apiToken")
                }
            }
        )
            .then(res => {
                loadOrderObject(id);
            })
            .catch(err => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const loadResi = () => {
        Api.post(
            "/cek-resi",
            {
                courier: orderObj.courier,
                resi: orderObj.resi
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("apiToken")
                }
            }
        ).then(res => {
            setResiObj(res.data.data.data);
        });
    };

    const loadStepOrders = () => {
        setStepOrders([
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-receipt"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
                    </svg>
                ),
                title: "Pesanan Dibuat",
                date: orderObj.date
            },
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-cash"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
                        <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                    </svg>
                ),
                title: orderObj.paid_date ? "Pesanan Dibayarkan" : "Belum Bayar",
                date: orderObj.paid_date
            },
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-truck"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                        <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
                    </svg>
                ),
                title: orderObj.sent_date ? "Pesanan Dikirimkan" : "Sedang Dikemas",
                date: orderObj.sent_date
            },
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l3 3l8 -8" />
                        <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                    </svg>
                ),
                title: orderObj.completed_date ? "Pesanan Selesai" : "Dikirim",
                date: orderObj.completed_date
            },
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-star"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                    </svg>
                ),
                title: orderObj.review_date ? "Pesanan Dinilai" : "Belum Dinilai",
                date: orderObj.review_date
            }
        ]);
    };

    const loadOrderObject = orderId => {
        Api.get("/order/" + orderId, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("apiToken")
            }
        }).then(res => {
            setOrderObj(res.data.data);
        });
    };

    return (
        <AccountOrderLayoutComponent position={"Orders"} breadcrumb={breadcrumb} title={""}>
            <div className="top-nav-order-detail">
                <a href="/account/orders">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-left"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l14 0" />
                        <path d="M5 12l4 4" />
                        <path d="M5 12l4 -4" />
                    </svg>
                    KEMBALI
                </a>
                <span>NO. PESANAN. {orderObj.invoice_id}</span>
            </div>
            <div className="order-status-track-wrap-wrapper">
                <div className="order-status-track-wrap">
                    <div className="progress-behind" />
                    <div
                        className="progress"
                        style={{
                            width:
                                (25 * stepOrders.filter(e => e.date != null).length >= 100
                                    ? 100
                                    : 25 * stepOrders.filter(e => e.date != null).length) +
                                2 +
                                "%"
                        }}
                    />
                    {stepOrders.map((stepOrderItem, index) => (
                        <div className={`item-wrap ${index == 0 ? "first" : ""}`}>
                            <div className={`icon-item-wrap`}>
                                <span />
                                <div>
                                    <div
                                        className={`icon-item ${
                                            stepOrderItem.date || (!stepOrderItem.date && index == 1) ? "active" : ""
                                        } ${stepOrders.filter(e => e.date != null).length == index ? "active-bg" : ""}`}
                                    >
                                        {stepOrderItem.icon}
                                    </div>
                                </div>
                                <span />
                            </div>

                            <div className="desc-step">
                                <p className="title-step">{stepOrderItem.title}</p>
                                <span className="date-step">{stepOrderItem.date}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {orderObj.invoice ? (
                <div className="faktur-card">
                    <p>Faktur</p>
                    <a href={orderObj.invoice}>Receipt</a>
                </div>
            ) : (
                <></>
            )}
            {!orderObj.paid_date && orderObj.status != "Cancel" && !orderObj.is_offline ? (
                <div className="soon-pay">
                    <div className="left">
                        <p>
                            Silahkan lakukan pembayaran sebelum tanggal <span>06/04/2024</span>
                        </p>
                    </div>
                    <div className="right">
                        <button
                            onClick={() => {
                                window.open(orderObj.payment?.invoice_url, "_blank");
                            }}
                            className="pay"
                        >
                            Bayar Sekarang
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                doCancel(orderObj.id);
                            }}
                            className="cancel"
                        >
                            Batal Pemesanan
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="envelope">
                <div className="left">
                    <h1>Alamat Pengiriman</h1>
                    <p className="name">{orderObj.address?.name}</p>
                    <p className="phone">{orderObj.address?.phone}</p>
                    <p className="address">{orderObj.address?.full_address}</p>
                </div>
                <div className="right">
                    <div className="store-copy">
                        <div className="detail">
                            <p className="title">
                                {orderObj.courier?.toUpperCase()} - {orderObj.courier_service}
                            </p>
                            <p>{orderObj.resi}</p>
                        </div>
                        <CopyToClipboard text={orderObj.resi}>
                            <button
                                type="button"
                                onClick={() => {
                                    toast.success("Nomor resi telah disalin");
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                </svg>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className="steps">
                        {resiObj.history ? (
                            resiObj.history.map((resiHisotoryItem, index) => (
                                <div className="step-item">
                                    <div className="bullet-wrap">
                                        <div>
                                            <div className={`bullet ${index == 0 ? "active" : ""}`} />
                                        </div>
                                    </div>
                                    <p className="date">{resiHisotoryItem.date}</p>
                                    <p className="desc">{resiHisotoryItem.desc}</p>

                                    <div className="step-item-progress" />
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="product-detail">
                {orderObj.detail?.map(orderDetail => (
                    <div className="row-data">
                        <div className="product-order-data">
                            <img src={orderDetail.product?.images[0]} alt="" />
                            <div className="product-item-data-text">
                                <h4>{orderDetail.product.name}</h4>
                                <span>
                                    {orderDetail.qty} pcs ({orderDetail.qty * orderDetail.product?.weight} gr)
                                </span>
                                <div className="mobile-price-qty">
                                    <div>{StringUtil.rupiahFormat(orderDetail.total)}</div>
                                    <div className="qty">x{orderDetail.qty}</div>
                                </div>
                            </div>
                        </div>
                        <div className="basic-row-data">
                            <h4>{StringUtil.rupiahFormat(orderDetail.total)}</h4>
                        </div>
                    </div>
                ))}
            </div>

            <div class="box-order-transaction">
                <div class="price-row">
                    <div>
                        <h4>Order Total</h4>
                        <h4>
                            {StringUtil.rupiahFormat(
                                orderObj.detail?.reduce((prev, curr) => {
                                    return prev + parseInt(curr.total);
                                }, 0)
                            )}
                        </h4>
                    </div>
                    <div>
                        <h4>Shipping Total</h4>
                        <h4>{StringUtil.rupiahFormat(orderObj.ongkir)}</h4>
                    </div>
                    <div class="total">
                        <h4>Total Payment</h4>
                        <h4>{StringUtil.rupiahFormat(orderObj.total)}</h4>
                    </div>
                </div>
            </div>
        </AccountOrderLayoutComponent>
    );
}
